/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable camelcase */
import dutchMessages from 'ra-language-dutch'
import { StringMap } from 'react-admin'

export const i18nDutch: StringMap = {
  ...dutchMessages,
  dashboard: {
    greeting: {
      morning: 'Goedemorgen',
      afternoon: 'Goedemiddag',
      evening: 'Goedenavond',
    },
    description: 'Welkom bij xxllnc bezwaren.',
    descriptionAdmin: 'Welkom bij xxllnc bezwaren.',
    uploadErrors: {
      unknown: 'Onbekende fout',
      noObjectionFound: 'Geen bezwaar gevonden',
      noReferenceFound: 'Geen referentie gevonden',
      working: 'Bezig met verwerken',
    },
    objectionStatus: 'Status per type bezwaar',
    objectionType: 'Soort bezwaren',
    topGrievances: 'Meest voorkomende grieven',
    gegrondOngegrond: 'Aantal bezwaren gegrond vs ongegrond',
  },
  resources: {
    objections: {
      list: 'Ingediende bezwaren',
      edit: 'Bezwaar behandelen',
      editShort: 'Behandelen',
      show: 'Bezwaar inzien',
      create: 'Bezwaar indienen',
      inboekvariabelen: 'Variabelen',
      decision: 'Beslissing',
      decisionMotivation: 'Motivatie',
      decisionMotivationHelperText: 'U kunt optioneel aanvullende informatie toevoegen. ' +
      'De AI zal deze informatie meenemen in het maken van de onderbouwing van de beslissing.',
      citaat: 'Citaat',
      additionalInfo: 'Aanvullende informatie',
      grieven: 'Grieven',
      grievance: 'Grief',
      waarden: 'WOZ Waarden',
      established_value: 'Vastgestelde waarde',
      claimed_value: 'Geclaimde waarde',
      corrected_value: 'Gecorrigeerde waarde',
      corrected_valueHelperText: 'Enkel invullen indien aangepast.',
      decisionLetter: 'Beslissing brief',
      createLetter: 'Er bestaat voor dit bezwaar nog geen conceptversie van de uitspraak. ' +
      'Gebruik de knop hieronder om de briefteksten voor de uitspraak te genereren, op basis van ' +
      'de verwerkte grieven.\n\n' +
      'Wanneer je later wijzigingen aanbrengt in de grieven stap, worden gewijzigde briefteksten ' +
      'ter controle gemarkeerd.',
      createLetterHelperText: 'Vink deze optie aan om de beslissing brief teksten (onieuw) te genereren voor de aanvrager. ' +
      'Het kan een aantal minuten duren voordat de teksten zijn gegenereerd.',
      adviceTexts: 'Advies teksten',
      decisionTexts: 'Beslissings-teksten',
      editLetter: 'Briefteksten bewerken',
      letterText: 'Brieftekst',
      hasHashtag: 'Bevat nog placeholders',
      hasHashtagInstructions: 'Bevat nog placeholders. Controleer de tekst en vul de ontbrekende informatie in.',
      hasChanged: 'De placeholders zijn door de AI gewijzigd.',
      hasChangedInstructions: 'De placeholders zijn door de AI of behandelaar gewijzigd. ' +
      'Controleer de tekst en vul de ontbrekende informatie in.',
      notReady: 'Let op: De AI is de tekst nog aan het wijzigen. Druk refresh om de nieuwe tekst te zien.',
      decisionAdviceText: 'Beslissingtekst',
      back: 'Terug naar overzicht',
      municipality: 'Gemeente',
      delete: 'Verwijder bezwaar',
      sideBySide: 'Toon bezwaar',
      hideSideBySide: 'Verberg bezwaar',
      organizationWarning: 'Let op: U werkt nu in de omgeving van \'%{organization}\'',
      fileType: {
        BEZWAAR: 'Bezwaar document',
        AANVULLING: 'Aanvulling van het bezwaar',
        HOORVERSLAG: 'Hoorverslag document',
        TAXATIE: 'Taxatierapport',
        MACHTIGING: 'Machtiging',
        null: 'Onbekend bestand',
      },
      upload: {
        button: 'Documenten uploaden',
        title: 'Upload extra doccumenten voor bezwaar %{id}',
        titleBulk: 'Upload bezwaar documenten',
        descriptionBulk: 'Upload meerdere bezwaar- en/of aanvullingsdocumenten tegelijk.',
        success: 'De documenten zijn geüpload en worden verwerkt.',
        municipalityHelpText: 'Het gemeente veld is alleen verplicht bij nieuwe bezwaren.',
        uploadDocuments: 'Upload documenten',
      },
      loading: {
        error: 'Oeps, er lijkt iets mis te zijn gegaan bij het verwerken van het document. ' +
        'Geen zorgen, je kunt het opnieuw proberen of even contact opnemen als het probleem blijft bestaan!',
        uploadingFile: 'Bezig met uploaden van het document.',
        textFromDoc: 'Bezig met het lezen van het document. Dit kan een paar minuten duren.',
        takesLonger: 'Het duurt helaas iets langer dan normaal... maar we zijn hard bezig om alles in goede banen te leiden!',
        grievagesFromObjection: 'De AI is druk bezig de grieven uit het document te halen. Nog héél even geduld!',
        propertyFromObjection: 'De inboek eigenschappen worden zorgvuldig uit het bezwaar gehaald. Dit is de laatste stap!',
        noGrievances: 'Er zijn geen grieven gevonden in het document. Controleer of u het juiste document heeft geupload.',
      },
      bulkImport: {
        title: 'Bulk import',
        warning: 'Selecteer een gemeente en ten minste één bezwaar.',
        upload: 'Importeren',
        cancel: 'Annuleren',
        completed: 'Bezwaren succesvol geüpload.',
        error: 'Er is een fout opgetreden bij het uploaden van de bezwaren.',
      },
      steps: {
        saveAndNext: 'Opslaan en naar \'%{step}\'',
        onlyNext: 'Naar \'%{step}\'',
        save: 'Opslaan',
        backTo: 'Terug naar \'%{step}\'',
        complete: 'Sla op en rond af',
        reOpen: 'Heropen bezwaar',
      },
      status: {
        STALLING: 'Error tijdens verwerken',
        RECEIVED: 'Ontvangen',
        UNDER_REVIEW: 'In behandeling',
        ADDITIONAL_INFORMATION_REQUESTED: 'Aanvullende informatie opgevraagd',
        ACCEPTED: 'Geaccepteerd',
        REJECTED: 'Afgewezen',
        REVISION_REQUESTED: 'Herziening aangevraagd',
        RESOLVED: 'Afgehandeld',
        CANCELLED: 'Geannuleerd',
      },
      grievanceStatus: {
        influenced: 'Waardevermindering',
        unfounded: 'Ongegrond',
        grounded: 'Gegrond',
      },
      grievanceUploadStatus: {
        WORKING: 'Bezig met verwerken',
        EXPECTING_FILE: 'Nog niet alle documenten die nodig zijn om dit bezwaar te behandelen zijn geupload.',
        READY: 'Klaar',
        ERROR: 'Fout',
        NO_GRIEVANCES: 'Geen grieven gevonden',
      },
      objectionType: {
        PARTICULIER: 'Particulier',
        EERLIJKE_WOZ: 'Eerlijke WOZ',
        HET_NIEUWE_WOZ_BUREAU: 'HNWB',
        JUIST: 'Juist',
        BEZWAAR_MAKER: 'Bezwaarmaker.nl',
        UNKNOWN: 'Onbekend',
      },
      properties: {
        uploadExtraDocuments: 'Upload extra documenten',
        uploadExtraDocumentsPlaceholders: 'Sleep hier uw documenten naartoe of klik om te uploaden. ' +
        'Deze documenten zullen door de AI verwerkt worden.',
        ProForma: 'Proforma',
        Ontvankelijk: 'Ontvankelijk',
        Horen: 'Horen',
        TaxatieverslagVerzocht: 'Taxatieverslag verzocht',
        Taxatieverslag: 'Taxatieverslag ontvangen',
        Onkostenvergoeding: 'Onkostenvergoeding',
        UitstelBetaling: 'Uitstel betaling',
        VoldoetAanFormeleVereisten: 'Voldoet aan formele vereisten',
        BevindingenTaxateur: 'Bevindingen taxateur',
        VergelekenWoningen: 'Vergeleken woningen',
        TaxatieGesprokenMet: 'Gesproken met',
        TaxatieSoortOpname: 'Soort opname',
        TaxatieObjectOmschrijving: 'Object omschrijving',
        compensation: {
          title: 'Vergoeding',
          indienenBezwaarschrift: 'Indienen bezwaarschrift',
          horen: 'Hoorzitting',
          taxatieVerslag: 'Taxatierapport',
          taxatieType: 'Taxatie type',
          BUREAU_TAXATIE: 'Bureau taxatie',
          TER_PLAATSE: 'Ter plaatse taxatie',
          INPANDIG: 'Inpandige taxatie',
        }
      },
      warnings: {
        noDecision: 'Er is nog geen beslissing genomen in stap 3 voor dit bezwaar.',
        noGriefDecisionTexts: 'Er zijn nog grieven zonder beslissingen.',
        aiWorkingOnLetterText: 'De AI is nog bezig met het updaten van de briefteksten. Een moment gedult alstublieft...',
        letterTextUpdated: 'De briefteksten zijn door de ai gewijzigd. Controleer de briefteksten en pas deze aan waar nodig.',
      },
      compare: {
        good: 'Goed',
        normal: 'Normaal',
        bad: 'Slecht',
      },
      decisions: {
        GROUNDED: 'Gegrond',
        UNFOUNDED: 'Ongegrond',
      },
      valueInfluence: {
        true: 'Waardevermindering',
        false: 'Geen waardevermindering',
      },
    },
    grievances: {
      create: 'Grief aanmaken',
      edit: 'Grief bewerken',
      list: 'Grieven',
      decision_texts: 'Beslissing teksten',
      importMessage: 'De grieven worden nu geimporteerd. Dit kan enkele seconden duren.',
      import: {
        title: 'Grieven importeren',
        description: 'Upload een CSV bestand met grieven om te importeren.',
        uploadDocument: 'Upload CSV bestand',
        uploadPlaceholder: 'Sleep hier uw bestand naartoe of klik om te uploaden. U kunt één csv/exel bestand tegelijk uploaden.',
      }
    },
    municipalities: {
      create: 'Gemeente aanmaken',
      edit: 'Gemeente bewerken',
      list: 'Gemeenten',
    },
    courts: {
      create: 'Rechtbank aanmaken',
      edit: 'Rechtbank bewerken',
      list: 'Rechtbanken',
    }
  },
  filters: {
    title: 'Filters',
    status: 'Status',
    type: 'Type',
    documentStatus: 'Documenten status',
  },
  data: {
    csvImportGrievances: 'Cvs import',
    importGrievances: 'Grieven importeren',
    selectOrganization: 'Kies een organisatie',
    referenceNumber: 'Kenmerk:',
    excludeDeleted: 'Verberg verwijderde bezwaren',
    user: 'Gebruiker',
    type: 'Type',
    externalReference: 'Externe referentie',
    complete: 'Compleet',
    recentLogs: 'Recent mislukte uploads',
    done: 'Afgehandeld',
    objectionsThisWeek: 'Bezwaren deze week',
    uploadFilesThisWeek: 'Bestanden geupload deze week',
    statusDescription: 'Status omschrijving',
    uploaded: 'Upload datum',
    matchedFiles: 'Gekoppelde bestanden',
    noFiles: 'Geen bestanden gevonden',
    objection: 'Bezwaar',
    fileType: 'Bestandstype',
    fileName: 'Bestandsnaam',
    objectionConfig: 'Object gegevens',
    objectionConfigDescription: 'WOZ-nummer, aanslagnummer en adresgegevens',
    subject: 'Belanghebbende',
    subjectDescription: 'Naam en adresgegevens',
    referentieNummer: 'Referentienummer',
    address: 'Adres',
    objectionType: 'Indiener',
    retry: 'Opnieuw proberen',
    teamName: 'Teamnaam',
    mailbox: 'Mailbox',
    postalCode: 'Postcode',
    city: 'Plaats',
    court: 'Rechtbank',
    grievancesBook: 'Grievenboek',
    phoneNumber: 'Telefoonnummer',
    municipalityCode: 'Gemeente code',
    valueInfluence: 'Waardevermindering',
    grievanceNumber: 'Griefnummer',
    name: 'Naam',
    explanation: 'Toelichting',
    viewDetails: 'Bekijk bezwaar details',
    downloadObjection_pdf: 'Download bezwaar',
    downloadObjection_letter: 'Download uitspraak',
    waardePeildatum: 'Waarde peildatum',
    decisionMotivation: 'Motivatie beslissing',
    straat: 'Straat',
    huisnummer: 'Huisnummer',
    woonplaats: 'Woonplaats',
    bouwjaar: 'Bouwjaar',
    eenheid: 'Eenheid',
    oppervlakte: 'Oppervlakte',
    verkoopdatum: 'Verkoopdatum',
    verkoopPrijs: 'Verkoopprijs',
    kwaliteit: 'Kwaliteit',
    onderhoud: 'Onderhoud',
    ligging: 'Ligging',
    object_woz_number: 'WOZ nummer',
    object_reference_number: 'Aanslagnummer',
    download: 'Download pdf',
    objectData: 'Object',
    objectWozNumber: 'WOZ nummer:',
    objecReferenceNumber: 'Aanslagnummer:',
    objectAdress: 'Object adres:',
    objections: 'Bezwaren',
    subjectData: 'Belanghebbende',
    revisions: 'Revisies',
    inProgress: 'In behandeling',
    new: 'Nieuw',
    lastMonth: 'Laatste maand',
    griefid: 'Grief ID',
    description: 'Omschrijving',
    completed: 'Afgerond',
    list: 'Bezwaren',
    gemeente: 'Gemeente',
    objection_number: 'Bezwaarnr',
    objection_number_long: 'Bezwaarnummer',
    dagtekening: 'Dagtekening',
    status: 'Status',
    created: 'Datum',
    deletedAt: 'Verwijderd op',
    lastUpdated: 'Laatst bijgewerkt',
    closingDate: 'Sluitingsdatum',
    proforma: 'Proforma',
    properties: 'Eigenschappen',
    citaat: 'Citaat',
    notReadyYet: 'Het bezwaar wordt op dit moment nog verwerkt. Een moment gedult alstublieft...',
  },
  login: {
    title: 'Inloggen',
    button: 'Inloggen',
    choose: 'Kies voor welke organisatie je wilt inloggen:',
    email: 'Email',
    emailHint: 'Vul je emailadres in om door te gaan.',
    fetchUserInfo: 'Een moment geduld, we halen je gebruikersinformatie op.',
    hint: 'Log in op mijn.xxllnc.nl',
    incognitoError: 'Authenticatie niet mogelijk in incognito mode',
    loginCompleted: 'Succesvol ingelogd!',
    unknownOrganizationId: 'Het opgegeven organisatie-id is onbekend',
    notValid: 'Het opgegeven emailadres is niet geldig',
    byXxllnc: 'Bezwaren is een applicatie van xxllnc',
    inactive: 'Je bent uitgelogd vanwege inactiviteit.',
  },
  page: {
    unauthorized: {
      headerTitle: 'Onbevoegd',
      defaultTitle: 'Onbevoegd',
      title: 'Voor deze actie ben je niet bevoegd.',
      text: 'Je hebt geprobeerd een actie uit te voeren waar je geen rechten voor hebt.',
    },
  },
  submenu: {
    settings: 'Beheer',
  },
}
