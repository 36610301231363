import { Box, BoxProps, Grid, Typography } from '@mui/material'
import React from 'react'
import {
  ArrayInput, BooleanInput, DateInput, FormDataConsumer,
  NumberInput, SelectInput, SimpleFormIterator, TextInput, useRecordContext, useTranslate
} from 'react-admin'
import { xxllncColor } from '../../../layout/themes'
import {
  InboekvariabelenOutput as Inboekvariabelen,
  ObjectionOut,
  ObjectionStatus,
  TaxatieType
} from '../../../types/apiRequests'
import { compareStatusChoices, taxationTypes } from '../utils/objectionUtils'
import ControlledAccordions from './Inboekvariabelen.accordion'

const FACTOR_WOZ_OZB = 0.25
const REIMBURSMENT_PER_POINT = 624 * FACTOR_WOZ_OZB
const TAXATION_PER_HOUR = 64.13
const BUREAU_TAXATION_AMOUNT = TAXATION_PER_HOUR * 2
const TER_PLAATSE_TAXATION_AMOUNT = TAXATION_PER_HOUR * 3
const INPANDIG_TAXATION_AMOUNT = TAXATION_PER_HOUR * 4

const InboekvariabelenEditStep: React.FC = () => {
  const translate = useTranslate()

  const record = useRecordContext()
  const disabled = record?.status !== ObjectionStatus.UNDER_REVIEW

  const getReimbursement = (inboekvariabelen: Inboekvariabelen): number => {
    const vergoeding = inboekvariabelen.Vergoeding
    if (!vergoeding) return 0

    let defaultReimbursement = 0
    if (vergoeding.indienenBezwaarschrift) defaultReimbursement += REIMBURSMENT_PER_POINT
    if (vergoeding.horen) defaultReimbursement += REIMBURSMENT_PER_POINT
    if (vergoeding.taxatieVerslag) {
      switch (vergoeding.taxatieType) {
        case TaxatieType.BUREAU_TAXATIE: defaultReimbursement += BUREAU_TAXATION_AMOUNT; break
        case TaxatieType.TER_PLAATSE: defaultReimbursement += TER_PLAATSE_TAXATION_AMOUNT; break
        case TaxatieType.INPANDIG: defaultReimbursement += INPANDIG_TAXATION_AMOUNT; break
      }
    }
    return defaultReimbursement
  }

  const BoxWithBackground: React.FC<React.PropsWithChildren<BoxProps>> = (props) => (
    <Box
      sx={{
        backgroundColor: xxllncColor.opBlue20,
        padding: 2,
        borderRadius: 1,
        marginTop: 2,
        ...props.sx,
      }}
      {...props}
    >
      {props.children}
    </Box>
  )

  const InsideBox: React.FC<React.PropsWithChildren<BoxProps>> = (props) => (
    <Box
      sx={{
        padding: '10px',
        margin: '20px 10px',
        backgroundColor: '#fff',
        ...props.sx,
      }}
      {...props}
    >
      {props.children}
    </Box>
  )

  const RightAlignedBoolenInput = (props: any) => (
    <BooleanInput
      {...props}
      sx={{
        'ml': 'auto',
        'display': 'flex',
        'justifyContent': 'space-between',
        'width': '100%',
        '& .MuiFormControlLabel-root': {
          marginLeft: 0,
          flex: 1,
          flexDirection: 'row-reverse',
        },
        '& .MuiSwitch-root': {
          marginLeft: 'auto',
        },
        '& .MuiFormHelperText-root': {
          display: 'none',
        },
      }}
    />
  )

  return (
    <Box display="flex" flexDirection="column" >
      <Typography variant="subtitle1" component="h2">
        {translate('resources.objections.inboekvariabelen')}
      </Typography>
      <DateInput
        source="inboekvariabelen.WaardePeildatum"
        label="data.waardePeildatum"
        fullWidth={false}
        InputProps={{
          disabled
        }}
        sx={{ maxWidth: 250 }}
      />
      <DateInput
        source="inboekvariabelen.dagtekening"
        label="data.dagtekening"
        fullWidth={false}
        InputProps={{
          disabled
        }}
        sx={{ maxWidth: 250 }}
      />

      <ControlledAccordions />

      <BoxWithBackground>
        <RightAlignedBoolenInput
          source="inboekvariabelen.ProForma"
          label="resources.objections.properties.ProForma"
          InputProps={{
            disabled
          }}
        />
      </BoxWithBackground>

      <BoxWithBackground>
        <RightAlignedBoolenInput
          source="inboekvariabelen.Ontvankelijk"
          label="resources.objections.properties.Ontvankelijk"
          InputProps={{
            disabled
          }}
        />

        <FormDataConsumer>
          {({ formData }: { formData: { inboekvariabelen?: { Ontvankelijk: boolean } } }) =>
            formData?.inboekvariabelen?.Ontvankelijk && (
              <InsideBox>
                <BooleanInput
                  source="inboekvariabelen.VoldoetAanFormeleVereisten"
                  defaultValue={true}
                  label="resources.objections.properties.VoldoetAanFormeleVereisten"
                  disabled
                />
              </InsideBox>
            )
          }
        </FormDataConsumer>
      </BoxWithBackground>

      <BoxWithBackground>
        <RightAlignedBoolenInput
          source="inboekvariabelen.Horen"
          label="resources.objections.properties.Horen"
          InputProps={{
            disabled
          }}
        />
      </BoxWithBackground>

      <BoxWithBackground>
        <RightAlignedBoolenInput
          source="inboekvariabelen.TaxatieverslagVerzocht"
          label="resources.objections.properties.TaxatieverslagVerzocht"
          InputProps={{
            disabled
          }}
        />
      </BoxWithBackground>

      <BoxWithBackground>
        <RightAlignedBoolenInput
          source="inboekvariabelen.Taxatieverslag"
          label="resources.objections.properties.Taxatieverslag"
          InputProps={{
            disabled
          }}
        />

        <FormDataConsumer>
          {({ formData }: { formData: ObjectionOut }) =>
            formData?.inboekvariabelen?.Taxatieverslag && (
              <InsideBox>
                {/* TODO: Wat doen we hier mee? */}
                {/* <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextInput
                      label="resources.objections.properties.TaxatieObjectOmschrijving"
                      source="inboekvariabelen.TaxatieObjectOmschrijving"
                      InputProps={{
                        disabled
                      }}
                    />
                  </Grid>
                </Grid>
                <TextInput
                  multiline
                  source="inboekvariabelen.BevindingenTaxateur"
                  label="resources.objections.properties.BevindingenTaxateur"
                  InputProps={{
                    disabled
                  }}
                /> */}
                <ArrayInput
                  source="inboekvariabelen.VergelekenWoningen"
                  label="resources.objections.properties.VergelekenWoningen"
                  disabled
                >
                  <SimpleFormIterator fullWidth>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <TextInput
                          label="data.straat"
                          defaultValue={formData.object_street}
                          source="straat"
                          fullWidth
                          InputProps={{
                            disabled
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextInput
                          label="data.huisnummer"
                          source="huisnummer"
                          InputProps={{
                            disabled
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          label="data.woonplaats"
                          defaultValue={formData.object_city}
                          source="woonplaats"
                          fullWidth
                          InputProps={{
                            disabled
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <TextInput
                          label="data.bouwjaar"
                          source="bouwjaar"
                          InputProps={{
                            disabled
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextInput
                          label="data.eenheid"
                          source="eenheid"
                          InputProps={{
                            disabled
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextInput
                          label="data.oppervlakte"
                          source="oppervlakte"
                          InputProps={{
                            disabled
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <DateInput
                          label="data.verkoopdatum"
                          source="verkoopdatum"
                          InputProps={{
                            disabled
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <NumberInput
                          label="data.verkoopPrijs"
                          source="verkoopPrijs"
                          InputProps={{
                            disabled
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <SelectInput
                          label="data.kwaliteit"
                          source="kwaliteit"
                          defaultValue={2}
                          choices={compareStatusChoices}
                          fullWidth
                          InputProps={{
                            disabled
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <SelectInput
                          label="data.onderhoud"
                          source="onderhoud"
                          defaultValue={2}
                          choices={compareStatusChoices}
                          fullWidth
                          InputProps={{
                            disabled
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <SelectInput
                          label="data.ligging"
                          source="ligging"
                          defaultValue={2}
                          choices={compareStatusChoices}
                          fullWidth
                          InputProps={{
                            disabled
                          }}
                        />
                      </Grid>
                    </Grid>
                  </SimpleFormIterator>
                </ArrayInput>
              </InsideBox>
            )
          }
        </FormDataConsumer>
      </BoxWithBackground>

      <BoxWithBackground>
        <RightAlignedBoolenInput
          source="inboekvariabelen.Onkostenvergoeding"
          label="resources.objections.properties.Onkostenvergoeding"
          InputProps={{
            disabled
          }}
        />

        <FormDataConsumer>
          {({ formData }: { formData: { inboekvariabelen?: Inboekvariabelen } }) => {
            const inboekvariabelen = formData?.inboekvariabelen
            if (!inboekvariabelen || !inboekvariabelen.Onkostenvergoeding) return

            const reimbursement = getReimbursement(inboekvariabelen)
            return (
              <InsideBox>
                <BooleanInput
                  source="inboekvariabelen.Vergoeding.indienenBezwaarschrift"
                  defaultValue={true}
                  label="resources.objections.properties.compensation.indienenBezwaarschrift"
                  disabled={disabled}
                />
                <BooleanInput
                  source="inboekvariabelen.Vergoeding.horen"
                  defaultValue={formData?.inboekvariabelen?.Horen}
                  label="resources.objections.properties.compensation.horen"
                  disabled={disabled}
                />
                <BooleanInput
                  source="inboekvariabelen.Vergoeding.taxatieVerslag"
                  defaultValue={formData?.inboekvariabelen?.Taxatieverslag}
                  label="resources.objections.properties.compensation.taxatieVerslag"
                  disabled={disabled}
                />
                <SelectInput
                  source="inboekvariabelen.Vergoeding.taxatieType"
                  style={!inboekvariabelen.Vergoeding?.taxatieVerslag ? { display: 'none' } : {}}
                  choices={taxationTypes}
                  defaultValue={TaxatieType.BUREAU_TAXATIE}
                  label="resources.objections.properties.compensation.taxatieType"
                  InputProps={{
                    disabled
                  }}
                />
                <span>
                  <b>{translate('resources.objections.properties.compensation.title')}: </b>
                € {reimbursement.toFixed(2)}
                </span>
              </InsideBox>
            )
          }
          }
        </FormDataConsumer>
      </BoxWithBackground>

      <BoxWithBackground>
        <RightAlignedBoolenInput
          source="inboekvariabelen.UitstelBetaling"
          label="resources.objections.properties.UitstelBetaling"
          disabled={disabled}
        />
      </BoxWithBackground>
    </Box>
  )
}

export default InboekvariabelenEditStep
