import { ObjectionDecision, TaxatieType } from '../../../types/apiRequests'

export const compareStatusChoices = [
  { id: '1', name: 'resources.objections.compare.good' },
  { id: '2', name: 'resources.objections.compare.normal' },
  { id: '3', name: 'resources.objections.compare.bad' },
]

export const objectionDecisionChoices = Object.values(ObjectionDecision).map(decision => ({
  id: decision,
  name: `resources.objections.decisions.${decision}`,
}))

export const taxationTypes = Object.values(TaxatieType).map(type => ({
  id: type,
  name: `resources.objections.properties.compensation.${type}`,
}))